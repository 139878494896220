import { config } from "@fortawesome/fontawesome-svg-core";
import type {
  LinksFunction,
  LoaderFunction,
  SerializeFrom } from
"@remix-run/node";
import {
  LiveReload,
  Outlet,
  PrefetchPageLinks,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useNavigation } from
"@remix-run/react";
import { json } from "@remix-run/server-runtime/dist/responses";
import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import styles from "./styles/app.css";
import tooltipStyles from "react-tooltip/dist/react-tooltip.css";
import fontawesomeStyles from "./styles/fortawesome.css";
import phoneInputStyles from "react-phone-input-2/lib/style.css";
import datepickerStyles from "react-datepicker/dist/react-datepicker.min.css";
import fontStyles from "./styles/fonts.css";
import extraCss from "./styles/extra.css";
import { cache } from "~/utils/cache";
import type { ParsedAccount } from "~/utils/hive";
import { getActiveAccount } from "~/session.server";
import { route } from "~/utils/route";
import nprogress from "nprogress";
import "nprogress/nprogress.css?__remix_sideEffect__";
import { ToastContainer } from "react-toastify";
import toastStyles from "react-toastify/dist/ReactToastify.css";
import type { ExternalScriptsFunction } from "remix-utils/external-scripts";
import { leocache } from "./utils/leocache";
import AppHeader from "./components/AppHeader";
import CustomErrorBoundary from "./components/ErrorBoundary";
import { useAppStore } from "./store";
import type { AccountSettings } from "./store/settings";
import { Head } from "./components/layout/Head";
import { Sidebar } from "./components/layout/Sidebar";
import { PublishButton } from "./components/layout/PublishButton";
import { Announcements } from "./components/layout/Announcements";
import { useLiveNotifications } from "./hooks/useLiveNotifications";
import { useRouteClientLoader } from "./hooks/useRouteClientLoader";
import { useRestoreTheme } from "./hooks/useRestoreTheme";
import { match } from "./utils/rustlike";

config.autoAddCss = false;

export const loader: LoaderFunction = async ({ request }) => {
  const activeAccountName = await getActiveAccount(request);

  const isLoggedIn = activeAccountName !== null;

  const [activeAccount, settings] = await (async () => {
    if (isLoggedIn) {
      return Promise.all([
      cache.getAccount(activeAccountName),
      leocache.getInfraSettings(activeAccountName)]
      );
    } else {
      return [null, null];
    }
  })();

  console.log({ settings, activeAccount });

  const domain = new URL(request.url).hostname;
  const isTest = true;

  const ref = new URL(request.url).searchParams.get("referral");
  const existingRef = request.headers.
  get("cookie")?.
  split("; ")?.
  find((cookie) => cookie?.startsWith("referral="))?.
  split("=")?.[1];

  return json(
    {
      env: {
        THREAD_NODE: "https://inleo.io/leocache",
        TEST: isTest
      },
      activeAccount,
      settings: settings?.settings
    },
    {
      headers: {
        "Cache-Control": "private, max-age=120",
        ...(ref && !existingRef ?
        { "Set-Cookie": `referral=${ref}; path=/; max-age=30` } :
        {})
      }
    }
  );
};

interface RootData {
  env: object;
  activeAccount: ParsedAccount | null;
  settings: AccountSettings;
}

export const links: LinksFunction = () => {
  return [
  {
    rel: "manifest",
    href: "/manifest.json"
  },
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: "/icons/apple-touch-icon.png"
  },
  {
    rel: "apple-touch-startup-image",
    href: "/icons/apple-launch-1125x2436.png"
  },
  {
    rel: "icon",
    href: "https://inleo.io/logo.svg",
    type: "image/svg"
  },
  { rel: "preload", href: styles, as: "style" },
  { rel: "stylesheet", href: styles },
  { rel: "preload", href: styles, as: "style" },
  { rel: "stylesheet", href: extraCss },
  { rel: "preload", href: fontawesomeStyles, as: "style" },
  { rel: "stylesheet", href: fontawesomeStyles },
  { rel: "preload", href: tooltipStyles, as: "style" },
  { rel: "stylesheet", href: tooltipStyles },
  { rel: "preload", href: toastStyles, as: "style" },
  { rel: "stylesheet", href: toastStyles },
  { rel: "preload", href: phoneInputStyles, as: "style" },
  { rel: "stylesheet", href: phoneInputStyles },
  { rel: "preload", href: datepickerStyles, as: "style" },
  { rel: "stylesheet", href: datepickerStyles },
  { rel: "preload", href: fontStyles, as: "style" },
  { rel: "stylesheet", href: fontStyles },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous"
  },
  {
    rel: "preconnect",
    href: "https://hive.inleo.io",
    crossOrigin: "anonymous"
  },
  {
    rel: "preconnect",
    href: "https://pagead2.googlesyndication.com",
    crossOrigin: "anonymous"
  }];

};

let scripts: ExternalScriptsFunction<SerializeFrom<typeof loader>> = () => {
  return [];
};
export let handle = { scripts };

export default function App() {
  const { activeAccount, settings, env } = (useLoaderData() as RootData);

  const [setActiveAccount, setPremium, setSettings] = useAppStore((store) => [
  store.account.setActiveAccount,
  store.account.setPremium,
  store.settings.setSettings]
  );

  const [appLoaded, setAppLoaded] = useState(false);

  const location = useLocation();
  const navigation = useNavigation();
  const darkMode = useRestoreTheme(settings, appLoaded);

  useLiveNotifications();
  useRouteClientLoader();

  // mark app loaded
  useEffect(() => {
    setAppLoaded(true);
    window.sessionStorage.setItem("TEST", "true");
  }, []);

  // previous route
  useEffect(() => {
    if (navigation.location) {
      route.previous = location.pathname;
    }
  }, [navigation.location, location.pathname]);

  // nprogress
  useEffect(() => {
    if (typeof window === "undefined") return;

    if (navigation.state === "loading") {
      nprogress.start();
    } else {
      nprogress.done();
    }
  }, [navigation]);

  // fill store
  useEffect(() => {
    if (
    typeof setActiveAccount !== "function" ||
    typeof setSettings !== "function")

    return;

    setActiveAccount(activeAccount);
    setSettings(settings);

    if (activeAccount) {
      setPremium({ ...activeAccount.premium, fetched: true });
    }

    if (settings !== undefined) {
      window?.localStorage?.setItem(
        "account-settings",
        JSON.stringify(settings)
      );
    }
  }, [activeAccount, settings, setActiveAccount, setSettings]);

  const itemType = match(
    {
      [location?.pathname?.startsWith("/profile")]: () =>
      "https://schema.org/ProfilePage",
      null: () => ""
    },
    true
  );

  const isPostReadingPage = useMemo(() => {
    return location.pathname?.startsWith("/@");
  }, [location]);

  if (location.pathname === "/sitemap.txt") return <Outlet />;
  if (
  location.pathname === "/login" ||
  location.pathname?.startsWith("/signup"))
  {
    return (
      <html
        lang="en"
        className={classNames(
          "min-h-screen text-xs sm:text-base flex flex-col overflow-y-scroll opacity-0",
          { dark: darkMode, "opacity-100": appLoaded }
        )}>
        
        <Head settings={settings} />

        <body className="flex-1 flex flex-col bg-pri dark:bg-pri-d">
          <Outlet />
          <LiveReload />
          <Scripts />
          <script async defer src="https://api.inleo.io/latest.js" />
          <noscript>
            <img
              src="https://api.inleo.io/noscript.gif"
              alt=""
              referrerPolicy="no-referrer-when-downgrade" />
            
          </noscript>
          <ToastContainer />
          <ScrollRestoration />
        </body>
      </html>);

  }

  return (
    <html
      lang="en"
      className={classNames(
        "min-h-screen text-xs sm:text-base flex flex-col opacity-0",
        {
          dark: darkMode,
          "opacity-100": appLoaded,
          "snap-y snap-mandatory scrollbar-w-0 scroll-smooth overflow-y-scroll overflow-x-hidden [scroll-padding-block-start:3rem]":
          location.pathname?.startsWith("/shorts")
        }
      )}>
      
      <Head settings={settings} />

      <body
        className="flex-1 flex flex-col bg-pri dark:bg-pri-d"
        itemType={itemType}
        itemScope>
        
        {activeAccount &&
        <PrefetchPageLinks page={`/profile/${activeAccount?.name}`} />}
        

        <div
          className={classNames("flex-1 flex w-full", {
            "pc:justify-center": !location.pathname?.startsWith("/publish"),
            "!pc:justify-start !max-w-[100vw]":
            location.pathname?.startsWith("/publish"),
            "justify-between":
            location.pathname !== "/posts" && (
            location.pathname?.startsWith("/posts") ||
            location.pathname?.startsWith("/@"))
          })}
          suppressHydrationWarning>
          
          <AppHeader />
          <Sidebar />
          <PublishButton />

          <main
            className={classNames(
              "relative flex w-full pc:w-[68rem] pb-[55px] md:pb-20 pc:pb-0 sm:pl-[4.5rem] pc:pl-0",
              {
                "pc:w-screen": location.pathname?.startsWith("/publish"),
                "justify-center":
                location.pathname !== "/posts" &&
                location.pathname?.startsWith("/posts"),
                "justify-start pc:w-8/12 pc:max-w-[calc(823px)]":
                isPostReadingPage
              }
            )}
            suppressHydrationWarning>
            
            <Outlet />
          </main>

          <Announcements />
          <ToastContainer />
        </div>
        <LiveReload />
        <Scripts />
        <script async defer src="https://api.inleo.io/latest.js" />
        <noscript>
          <img
            src="https://api.inleo.io/noscript.gif"
            alt=""
            referrerPolicy="no-referrer-when-downgrade" />
          
        </noscript>
        <ScrollRestoration />
      </body>
    </html>);

}

export function ErrorBoundary() {
  return <CustomErrorBoundary />;
}